import React, { useEffect } from "react";

import AOS from 'aos';

import Home from './components/pages/Home';
import About from "./components/pages/About";
import Features from "./components/pages/Features";
import Action from "./components/common/Action";
import Services from "./components/pages/Services";
import Contact from "./components/pages/Contact";
import Footer from "./components/common/Footer";
import Fancy from "./components/common/Fancy";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });

  }, []);

  return (
    <>
      <Home />
      <About />
      <Features />
      <Action />
      <Services />
      <Contact />
      <Footer />
      <Fancy />
    </>
  );
}

export default App;
