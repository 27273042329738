import React from "react";

const Footer = () => {
    return (
        <footer id="footer" className="footer dark-background">
            <div className="container">
                <h3 className="sitename">enfochannel</h3>
                <p>make IT pragmatic.</p>
                <div className="social-links d-flex justify-content-center">
                    <a href="www.enfochannel.com"><i className="bi bi-stack-overflow" /></a>
                    <a href="www.enfochannel.com"><i className="bi bi-github" /></a>
                    <a href="www.enfochannel.com"><i className="bi bi-youtube" /></a>
                    <a href="www.enfochannel.com"><i className="bi bi-linkedin" /></a>
                    <a href="www.enfochannel.com"><i className="fa-brands fa-jira" /></a>
                    <a href="www.enfochannel.com"><i className="bi bi-twitter-x" /></a>
                </div>
                <div className="container">
                    <div className="copyright">
                        <span>Copyright</span> <strong className="px-1 sitename">Pragmatic Business Consultants Inc.</strong> <span>All Rights Reserved</span>
                    </div>
                    <div className="credits">
                        {/* All the links in the footer should remain intact. */}
                        {/* You can delete the links only if you've purchased the pro version. */}
                        {/* Licensing information: https://bootstrapmade.com/license/ */}
                        {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer