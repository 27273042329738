import React, { useState, useEffect } from "react";

const Fancy = () => {

    const [isPreloader, setIsPreloader] = useState("");
    useEffect(() => {
        const preloader = document.querySelector('#preloader');
        if (preloader) {
            setIsPreloader(true);
        }
    }, []);

    let scrollTop = document.querySelector('.scroll-top');
    if(scrollTop) {
        const toggleScrollTop = () => {
          window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        }
        scrollTop.addEventListener('click', (e) => {
          e.preventDefault();
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
    
        window.addEventListener('load', toggleScrollTop);
        document.addEventListener('scroll', toggleScrollTop);
    
      }

    const toggleScrolled = () => {

        const selectBody = document.querySelector('body');

        const selectHeader = document.querySelector('#header');

        if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
        window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    }

    if (toggleScrolled) {
        document.addEventListener('scroll', toggleScrolled);
        window.addEventListener('load', toggleScrolled);
    }

    return (
        <>
            {/* Scroll Top */}
            <a href="/home" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short" />
            </a>

            {/* Preloader */}
            {
                !isPreloader ? <div id="preloader" /> : <div />
            }
        </>
    )
}

export default Fancy;