import React from "react";

const Features = () => {
    return (
        <>
            {/* Features Section */}
            < section id="features" className="features section" >
                <div className="container">
                    {/* Replaced all data-bs-target with href */}
                    <ul className="nav nav-tabs row  d-flex" data-aos="fade-up" data-aos-delay={100}>
                        <li className="nav-item col-3">
                            <a className="nav-link active show" data-bs-toggle="tab" href="#features-tab-1">
                                <i className="bi bi-activity" />
                                <h4 className="d-none d-lg-block">Ālēkha</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" href="#features-tab-2">
                                <i className="bi bi-pie-chart-fill" />
                                <h4 className="d-none d-lg-block">Business Analytics</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" href="#features-tab-3">
                                <i className="bi bi-shield-fill" />
                                <h4 className="d-none d-lg-block">Identity as a Service</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" href="#features-tab-4">
                                <i className="bi bi-brightness-high" />
                                <h4 className="d-none d-lg-block">Health Portal</h4>
                            </a>
                        </li>
                    </ul>{/* End Tab Nav */}
                    <div className="tab-content" data-aos="fade-up" data-aos-delay={200}>
                        <div className="tab-pane fade active show" id="features-tab-1">
                            <div className="row">
                                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>Ālēkha (Graph) is a dashboard for the organizations to monitor their systems 24/7/365.</h3>
                                    <p className="fst-italic">
                                        It can be deployed on your existing workload to provide observability and monitoring dashboard using advanced AI tools
                                        and techniques.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check2-all" />
                                            <span>Single pane of glass for your hardware and software.</span>
                                        </li>
                                        <li><i className="bi bi-check2-all" /> <span>Advance AI techniques to detect any vulnerabilities and inconsistencies.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Alerts and notifications to any abnormalities and anomalies.</span></li>
                                    </ul>
                                    <p>
                                        Modern attacks are more sophisticated and diificult to trace with traditional apporach of system monitoring.
                                        Organizations should not only rely on humans alone to fight agaist the cyberattacks, threats, vulnerabilities,
                                        abnormalities, and anomalies. It needs sophisticated tools that can make teams life easy, as nobody wants
                                        to get up in the middle of the night to receive the system down calls.
                                        Cognition provides agnostic tools and services that you can deploy with your existing application, regardless where
                                        the workload is running, cloud versus on-premises.
                                    </p>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/detail-overview-workload.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>{/* End Tab Content Item */}
                        <div className="tab-pane fade" id="features-tab-2">
                            <div className="row">
                                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>Data-Driven Business Analytics</h3>
                                    <p>
                                        A Path to Informed Decision-Making In today’s
                                        rapidly evolving business environment, data is more valuable than ever.
                                        Companies across industries are embracing data-driven business analytics to gain a competitive edge,
                                        optimize operations, and enhance customer experiences.
                                    </p>
                                    <p className="fst-italic">
                                        The biggest challenge we came across with the organizations is that their data is scattered across various
                                        systems, it is unstructured and too much manual intervention to process the data.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check2-all" /> <span>Improved Decision-Making.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Enhanced Customer Experience.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Operational Efficiency.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Competitive Advantage.</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/abc5b6f4-8c31-4a0c-8a85-1e6c9d46532b.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>{/* End Tab Content Item */}
                        <div className="tab-pane fade" id="features-tab-3">
                            <div className="row">
                                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>Identity as a Service (IDaaS)</h3>
                                    <p>
                                        The Federal Trade Commission (FTC) estimates that as many as 9 million Americans have had their identities stolen each year.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check2-all" /> <span>Zero Trust Model.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Identity and Access Management (IAM)</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Role-based Access Controls (RBAC).</span></li>
                                    </ul>
                                    <p className="fst-italic">
                                        Identity as a service (IDaaS) comprises cloud-based solutions for identity and access management (IAM) functions, such as single sign-on (SSO).
                                        Your best bet is to improve the cyber awareness of your employees with regard to cybersecurity best practices and put policies in place that will limit the possibility of devastating human errors and help mitigate damage in case of a mistake.
                                    </p>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/working-3.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>{/* End Tab Content Item */}
                        <div className="tab-pane fade" id="features-tab-4">
                            <div className="row">
                                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>imfit.online</h3>
                                    <p>
                                        Doctors and Pharmacists, equipped with the technology can make your life happy.
                                    </p>
                                    <p className="fst-italic">
                                        imfit.online provides a centralized health data management solution. It encourages users to make healthy habbits by
                                        giving rewards and discounts to services. The portal use advanced machine learning and analytics to arrange
                                        healthy compititions and enables different business avenues.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check2-all" /> <span>Your Health Habbits.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Quick Prescriptions.</span></li>
                                        <li><i className="bi bi-check2-all" /> <span>Real Time Analytics and Global access to your health.</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/working-4.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>{/* End Tab Content Item */}
                    </div>
                </div>
            </section > {/* /Features Section */}
        </>

    );
};

export default Features