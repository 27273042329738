import React from "react";

const Action = () => {
    return (
        <>
            {/* Call To Action Section */}
            <section id="call-to-action" className="call-to-action section dark-background">
                <div className="container">
                    <div className="row" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="col-xl-9 text-center text-xl-start">
                            <h3>Call To Action</h3>
                            <p>Please reach-out to us for further details you wish to know on variety of the business propositions we offer.</p>
                        </div>
                        <div className="col-xl-3 cta-btn-container text-center">
                            <a className="cta-btn align-middle" href="index.html">Call To Action</a>
                        </div>
                    </div>
                </div>
            </section>{/* /Call To Action Section */}
        </>

    );
};

export default Action