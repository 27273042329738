import React from "react";

import Navigation from "../common/Navigation";

const Home = () => {
    
    return (
        <>
            <Navigation />            
            <main className="main">
                <section id="home" className="hero section dark-background">
                    <div id="hero-carousel" data-bs-interval={5000} className="container carousel carousel-fade" data-bs-ride="carousel">
                        {/* Slide 1 */}
                        <div className="carousel-item active">
                            <div className="carousel-container">
                                <h2 className="animate__animated animate__fadeInDown">Welcome to enfochannel</h2>
                                <p className="animate__animated animate__fadeInUp">We are the industry experts with two decades of experience in IT systems of Finance and B2B applications. We
                                    understand your business' technological needs and hence we can offer different pragmatic propositions that can
                                    suit your business.</p>
                                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                        {/* Slide 2 */}
                        <div className="carousel-item">
                            <div className="carousel-container">
                                <h2 className="animate__animated animate__fadeInDown">Pragmatic Business Consultants</h2>
                                <p className="animate__animated animate__fadeInUp">Make IT Pragmatic</p>
                                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                        {/* Slide 3 */}
                        <div className="carousel-item">
                            <div className="carousel-container">
                                <h2 className="animate__animated animate__fadeInDown">imfit.online</h2>
                                <p className="animate__animated animate__fadeInUp">
                                    imfit.online provides a centralized health data management solution. It encourages users to make healthy habbits by
                                    giving rewards and discounts to services. The portal use advanced machine learning and analytics to arrange
                                    healthy compititions and enables different business avenues.
                                </p>
                                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
                        </a>
                        <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                            <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
                        </a>
                    </div>
                    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
                        <defs>
                            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="wave1">
                            <use xlinkHref="#wave-path" x={50} y={3} />
                        </g>
                        <g className="wave2">
                            <use xlinkHref="#wave-path" x={50} y={0} />
                        </g>
                        <g className="wave3">
                            <use xlinkHref="#wave-path" x={50} y={9} />
                        </g>
                    </svg>
                </section>
            </main>
        </>
    );
};

export default Home