import React from "react";

const About = () => {
    return (
        <>
            {/* About Section */}
            <section id="about" className="about section">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>About</h2>
                    <p>Who we are</p>
                </div>{/* End Section Title */}
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay={100}>
                            <p>
                                We are the industry experts with two decades of experience in IT systems of Finance and B2B applications.
                                We understand your business' technological needs and hence we can offer different pragmatic propositions that can suit your business.
                            </p>
                            <ul>
                                <li><i className="bi bi-check2-circle" /> <span>Businesses analytics using data driven AI.</span></li>
                                <li><i className="bi bi-check2-circle" /> <span>Security for customers and their data is our utmost priority.</span></li>
                                <li><i className="bi bi-check2-circle" /> <span>Scalable solutions to meet business.</span></li>
                            </ul>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                            <p>The business world is changing fast. We understand that it is overwhelming for businesses to keep up with that pace using AI, providing security for its customers and their data, becoming more agile, resilent and scalable to meet global customer demands. If you do not have adequate resources to run your business in this type of environment, then you may be taking a risk. That leads you think to revisit your operating model. We are here to help you. We can build your IT infrastructure according to your business demands pragmatically.</p>
                            <a href="/about" className="read-more"><span>Read More</span><i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                </div>
            </section>{/* /About Section */}

        </>

    );
};

export default About