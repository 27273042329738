import React from "react";

const Services = () => {
    return (
        <>
            {/* Services Section */}
            <section id="services" className="services section">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>Solutions</h2>
                    <p>What we do offer</p>
                </div>{/* End Section Title */}
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={100}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="bi bi-google" style={{ color: '#008cff' }} />
                                </div>
                                <a href="index.html" className="stretched-link">
                                    <h3>Google Cloud</h3>
                                </a>
                                <p>Google Cloud Services.</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="fa-brands fa-aws" style={{ color: '#ff7300' }} />
                                </div>
                                <a href="index.html" className="stretched-link">
                                    <h3>AWS</h3>
                                </a>
                                <p>Amazon Web Services.</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="bi bi-windows" style={{ color: '#000000' }} />
                                </div>
                                <a href="index.html" className="stretched-link">
                                    <h3>Azure Cloud</h3>
                                </a>
                                <p>Azure Cloud (on demand only).</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="fa-brands fa-android" style={{ color: '#df1529' }} />
                                </div>
                                <a href="service-details.html" className="stretched-link">
                                    <h3>Andriod Studio</h3>
                                </a>
                                <p>Andriod application development.</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="fa-brands fa-docker" style={{ color: '#0db7ed' }} />
                                </div>
                                <a href="service-details.html" className="stretched-link">
                                    <h3>Containerization</h3>
                                </a>
                                <p>Application - Microservices</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={600}>
                            <div className="service-item position-relative">
                                <div className="icon">
                                    <i className="fa-solid fa-paper-plane" style={{ color: '#03a100' }} />
                                </div>
                                <a href="service-details.html" className="stretched-link">
                                    <h3>Open for new Partnerships</h3>
                                </a>
                                <p>Lets grow together.</p>
                            </div>
                        </div>{/* End Service Item */}
                    </div>
                </div>
            </section>
            {/* /Services Section */}

        </>

    );
};

export default Services