import React, { useEffect, useRef, useState } from "react";

const Navigation = () => {

    const mobileNavToggleBtn = useRef();

    const [isToggled, setIsToggled] = useState(false);

    function mobileNavToogle() {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        setIsToggled((prev) => !prev);
    }

    useEffect(() => {
        if (mobileNavToggleBtn && mobileNavToggleBtn.current) {
            mobileNavToggleBtn.current.addEventListener('click', mobileNavToogle);
        }
    }, []);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll('#navmenu a').forEach(navmenu => {
        navmenu.addEventListener('click', () => {
            if (document.querySelector('.mobile-nav-active')) {
                mobileNavToogle();
            }
        });
    });
    
    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo d-flex align-items-center">
                    {/* Uncomment the line below if you also wish to use an image logo */}
                    {/* img src="assets/img/logo.png" alt="" */}
                    <h1 className="sitename">enfochannel</h1>
                </a>
                <nav id="navmenu" className="navmenu">
                    <ul>
                        <li><a href="#home" className="active">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#features">Features</a></li>
                        <li><a href="#call-to-action">Action</a></li>
                        <li><a href="#services">Solutions</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                    {/*<i ref={mobileNavToggleBtn} className="mobile-nav-toggle d-xl-none bi bi-list" />*/}
                    <i ref={mobileNavToggleBtn} className={!isToggled ? 'mobile-nav-toggle d-xl-none bi bi-list' : 'mobile-nav-toggle d-xl-none bi bi-list bi-x'} />
                </nav>
            </div>
        </header>
    );
};

export default Navigation